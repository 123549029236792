import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import {
  ClockIcon,
  EmojiHappyIcon,
  RefreshIcon,
  GiftIcon,
  TrendingUpIcon,
  CashIcon,
  UserGroupIcon,
  LockClosedIcon,
  ChevronDownIcon
} from '@heroicons/react/outline'

import Layout from "../components/layout"
import Seo from "../components/seo"

const features = [
  {
    name: 'Important Dates',
    description: 'You will be able to enter the giveaway on this page on Saturday, 28th August. The exclusive MoonDash x DOGE NFT will also premiere at the same time. The giveaway will run for 1 week exactly and winners will be announced shortly after 18:00 UTC on the 4th September.',
    icon: ClockIcon,
  },
  {
    name: 'Entry cost',
    description: 'To enter, you must simply hold the required amount of MoonDash tokens. There will be 3 tiers for entries. Hold at least 50,000,000 (50 Million) to get 1 entry. Hold at least 400,000,000 (400 Million) to get 10 entries. Hold at least 1,750,000,000 (1.75 Billion) to get 50 entries. You will also be able to get entries for free by completing various social sharing/upvoting tasks.',
    icon: CashIcon,
  },
  {
    name: 'How much can you win?',
    description: 'We\'ll be picking a number of winners to share the 7,500 DOGE winnings pot. There will be 5 winners who will share 500 DOGE each and 1 super lucky winner who will get the grand prize of 5,000 DOGE! We\'ll also be giving out our exclusive rare MoonDash x DOGE NFT to 1 lucky winner!',
    icon: GiftIcon,
  },
  {
    name: 'Lots of Happy Winners',
    description: 'When we launched MoonDash, we ran BNB giveaways and have given out over $20,000 worth of tokens to date! We\'ve now decided to branch out and run more amazing giveaway events where we collaborate with other exciting tokens to reward both communities. MoonDash x DOGE is our first such giveaway event and there will be many more to come!',
    icon: EmojiHappyIcon,
  },

]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}



const GiveawayDashboardPage = () => {
  return (
    <Layout>
      <Seo title="Giveaway Dashboard" />
      <div className="text-white">
        <div className="container grid grid-cols-1 sm:grid-cols-12 gap-4 sm:gap-12 md:gap-16 lg:gap-20 pt-6 sm:pt-0 relative items-center">
          <div className="circle absolute z-0 -top-48 md:-top-48 lg:-top-96 -right-48 lg:-right-64"></div>
          <div className="col-span-8 pt-2 sm:pt-6 md:pt-12 lg:pt-24 xl:pt-32 text-left relative z-30 order-2 sm:order-1">
            <h1 className="text-base md:text-lg lg:text-xl font-medium tracking-wider text-blue-500 uppercase py-3 px-6 lg:py-4 lg:px-8 bg-blue-500 bg-opacity-10 rounded-full inline-block mb-6">Giveaway</h1>
            <h2 className="text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-display font-bold mb-4 lg:mb-6 xl:mb-8 max-w-xs sm:max-w-sm md:max-w-4xl">
              Win 7,500 DOGE & an exclusive rare NFT</h2>
            <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl xl:leading-relaxed mb-5 lg:mb-7 xl:mb-8 max-w-xs sm:max-w-sm md:max-w-3xl text-gray-200">The more MoonDash you hold, the more entries to the giveaway you'll have, so get in early and load up on your tokens! Entries will open on Saturday, the 28th of August. Make sure to join our Telegram group for all the updates!</p>
          </div>

          <div className="col-span-4 lg:py-8 order-1 sm:order-2">
            <div className="relative">

              <StaticImage
                src="../images/hero-giveaway-full.png"
                className="z-20 w-28 lg:w-full lg:max-w-sm"
                width={384}
                alt=""
                objectFit="contain"
              />

            </div>

          </div>

        </div>
        <div className="container">
          <div className="py-12 mt-12 sm:py-16 sm:mt-16 lg:py-24 lg:mt-24 border-t border-gray-700">
          <div className="grid grid-cols-1 gap-x-12 gap-y-12 sm:grid-cols-2 lg:grid-cols-2 lg:gap-x-16 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name}>
                <div>
                  <span className="flex items-center justify-center h-12 w-12 rounded-full bg-blue-500 bg-opacity-10">
                    <feature.icon className="h-6 w-6 text-blue-500" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-4">
                  <h3 className="text-lg md:text-xl lg:text-2xl font-medium text-white">{feature.name}</h3>
                  <p className="mt-2 text-base md:text-lg text-gray-300">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
          </div>
        </div>
      </div>



    </Layout>
  )
}

export default GiveawayDashboardPage

